// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  font-size: 16px;
  color: var(--black);
  font-family: "Inter", sans-serif;
}

button,
input {
  outline: none;
  border: none;
  background: transparent;
}

button {
  cursor: pointer;
}

#root {
  overflow-x: hidden;
}

.container {
  margin: 0 auto;
  padding: 0 230px;
}

@media screen and (max-width: 1500px) {
  .container {
    padding: 0 10vw;
  }
}
:root {
  --black: #0a1f33;
  --grey: #8a8e99;
  --line: #dadee5;
  --white: #fff;
  --green: #00e9be;
  --pink: #ff7597;
  --yellow: #ffc700;
  --light-grey: #f7f8fd;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,SAAA;EACA,qBAAA;EACA,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,gCAAA;AACJ;;AAEA;;EAEI,aAAA;EACA,YAAA;EACA,uBAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,cAAA;EACA,gBAAA;AACJ;;AAEA;EACI;IACI,eAAA;EACN;AACF;AAEA;EACI,gBAAA;EACA,eAAA;EACA,eAAA;EACA,aAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,qBAAA;AAAJ","sourcesContent":["* {\n    padding: 0;\n    margin: 0;\n    text-decoration: none;\n    list-style: none;\n    font-size: 16px;\n    color: var(--black);\n    font-family: 'Inter', sans-serif;\n}\n\nbutton,\ninput {\n    outline: none;\n    border: none;\n    background: transparent;\n}\n\nbutton { \n    cursor: pointer;\n}\n\n#root {\n    overflow-x: hidden;\n}\n\n.container {\n    margin: 0 auto;\n    padding: 0 230px;\n}\n\n@media screen and (max-width: 1500px) {\n    .container {\n        padding: 0 10vw;\n    }\n}\n\n:root {\n    --black: #0a1f33;\n    --grey: #8a8e99;\n    --line: #dadee5;\n    --white: #fff;\n    --green: #00e9be;\n    --pink: #ff7597;\n    --yellow: #ffc700;\n    --light-grey: #f7f8fd;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
